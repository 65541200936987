exports.components = {
  "component---src-pages-2021-index-js": () => import("./../../../src/pages/2021/index.js" /* webpackChunkName: "component---src-pages-2021-index-js" */),
  "component---src-pages-2021-outcomes-js": () => import("./../../../src/pages/2021/outcomes.js" /* webpackChunkName: "component---src-pages-2021-outcomes-js" */),
  "component---src-pages-2021-panellists-js": () => import("./../../../src/pages/2021/panellists.js" /* webpackChunkName: "component---src-pages-2021-panellists-js" */),
  "component---src-pages-2023-index-js": () => import("./../../../src/pages/2023/index.js" /* webpackChunkName: "component---src-pages-2023-index-js" */),
  "component---src-pages-2023-organisations-js": () => import("./../../../src/pages/2023/organisations.js" /* webpackChunkName: "component---src-pages-2023-organisations-js" */),
  "component---src-pages-2023-outcomes-js": () => import("./../../../src/pages/2023/outcomes.js" /* webpackChunkName: "component---src-pages-2023-outcomes-js" */),
  "component---src-pages-2023-panellists-js": () => import("./../../../src/pages/2023/panellists.js" /* webpackChunkName: "component---src-pages-2023-panellists-js" */),
  "component---src-pages-2024-topic-1-js": () => import("./../../../src/pages/2024/topic-1.js" /* webpackChunkName: "component---src-pages-2024-topic-1-js" */),
  "component---src-pages-2024-topic-2-js": () => import("./../../../src/pages/2024/topic-2.js" /* webpackChunkName: "component---src-pages-2024-topic-2-js" */),
  "component---src-pages-2024-topic-3-js": () => import("./../../../src/pages/2024/topic-3.js" /* webpackChunkName: "component---src-pages-2024-topic-3-js" */),
  "component---src-pages-2024-topic-4-js": () => import("./../../../src/pages/2024/topic-4.js" /* webpackChunkName: "component---src-pages-2024-topic-4-js" */),
  "component---src-pages-2024-topic-5-js": () => import("./../../../src/pages/2024/topic-5.js" /* webpackChunkName: "component---src-pages-2024-topic-5-js" */),
  "component---src-pages-2024-topic-6-js": () => import("./../../../src/pages/2024/topic-6.js" /* webpackChunkName: "component---src-pages-2024-topic-6-js" */),
  "component---src-pages-2024-topic-7-js": () => import("./../../../src/pages/2024/topic-7.js" /* webpackChunkName: "component---src-pages-2024-topic-7-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archived-pages-petitions-clean-air-2024-js": () => import("./../../../src/pages/archived-pages/petitions/clean-air-2024.js" /* webpackChunkName: "component---src-pages-archived-pages-petitions-clean-air-2024-js" */),
  "component---src-pages-archived-pages-petitions-index-js": () => import("./../../../src/pages/archived-pages/petitions/index.js" /* webpackChunkName: "component---src-pages-archived-pages-petitions-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outcomes-js": () => import("./../../../src/pages/outcomes.js" /* webpackChunkName: "component---src-pages-outcomes-js" */),
  "component---src-pages-participants-js": () => import("./../../../src/pages/participants.js" /* webpackChunkName: "component---src-pages-participants-js" */)
}

